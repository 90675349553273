document.addEventListener('DOMContentLoaded', function () {
	const btnShowHide = document.getElementById('showHideTape');
	if (!btnShowHide) return;
	const moreText = document.getElementById('tapeHiddenText');
	btnShowHide.addEventListener('click', function () {
		if (moreText.classList.contains('display-none')) {
			moreText.classList.remove('display-none');
			btnShowHide.textContent = 'Show less';
		} else {
			moreText.classList.add('display-none');
			btnShowHide.textContent = 'Show more';
		}
	});
});
